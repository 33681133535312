import { Box, Button, CardMedia, Link, Typography } from "@mui/material";
import { Scrollbar } from "swiper";

export default function BodyCard ({data, id}) {

    const styles = {
        container: {
            display: 'flex',
            minHeight: '100vh',
            maxHeight: 'fill-available',
            backgroundColor: data.background
        },
        wrapper: {
            display: 'flex',
            fleexDirection: 'row',
            flex:  '1 2 auto',
            alignItems: 'center',
            my: 3
        },
        leftSide: {
            display: 'flex',
            alignItems: 'center',
            flex: {md: 1, xs: 0}
        },
        rightSide: {
            flex: {md: 2, xs: 1}
        },
        rightSideWrapper: {
            maxWidth: '73%',
            margin: 'auto'
        },
        title: {
            fontFamily: 'Times, sans-serif',
            fontSize: 56,
            fontWeight: 'bold',
            color: data.titleColor
        },
        subtitle: {
            fontFamily: 'Times, sans-serif',
            fontSize: 28,
            fontWeight: 600,
            fontStyle: 'italic',
            marginBottom: 5,
            color: data.textColor
        },
        legendsContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            gap: 5,
            color: data.textColor
        },
        legendsTitle: {
            fontFamily: 'Times, sans-serif',
            fontSize: 20,
            fontWeight: 600,
            fontStyle: 'italic'
        },
        legendsText: {
            fontFamily: 'Montserrat, sans-serif',
            fontSize: 16,
            fontWeight: 400,
        },
        legendsMessage: {},
        legendsAction: {
            fontFamily: 'Times, sans-serif',
            fontSize: 26,
            fontWeight: 600,
            textTransform: 'Capitalize',
            float: 'right',
            marginTop: 10,
            minWidth: '325px',
            py: 2,
            color: data.buttonTextColor,
            backgroundColor: data.buttonBackgroundColor,
            "&:hover": {
                backgroundColor: data.titleColor,
            }
        }


    }

    return (
            <Box sx={styles.container} id={id}>
                <Box sx={styles.wrapper}>
                    <Box sx={styles.leftSide}>
                        <CardMedia 
                            src={data.image}
                            component={'img'}
                        />
                    </Box>
                    <Box sx={styles.rightSide}>
                        <Box sx={styles.rightSideWrapper}>
                            <Typography sx={styles.title}>{data.title}</Typography>
                            <Typography sx={styles.subtitle}>{data.subtitle}</Typography>
                            <Box sx={styles.legendsContainer}>
                                {
                                    data.leyenda.map((item , key) => (
                                        <Box key={key}>
                                            <Typography sx={styles.legendsTitle}>{item.title}</Typography>
                                            {
                                            item.text.split('\n').map((str, index, array) => (
                                                <Typography sx={styles.legendsText}>{str}</Typography>
                                            ))
                                            }
                                        </Box>
                                    ))
                                }
                            </Box>
                            <Typography sx={styles.legendsMessage}></Typography>
                            <Link href={`https://api.whatsapp.com/send?phone=+59894938312&text=${data.message}`} target="__blank">
                                <Button sx={styles.legendsAction}>Hablemos</Button>
                            </Link>
                        </Box>
                    </Box>
                </Box>
            </Box>
    )
}