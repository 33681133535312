import { Card, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import Vector20 from '../../assets/Vector20.svg';

const title = {
  fontFamily: 'Montserrat',
  color: '#3C3C3A',
  fontWeight: 700,
  fontSize: { md: 60, sm: 45, xs: 30 },
  textAlign: 'center',
  position: 'relative',
  zIndex: 999,
  marginTop: { md: '-15rem', sm: '-10rem', xs: '-5rem' }
};

const fontStyle = {
  fontFamily: 'Montserrat, sans-serif',
  fontSize: '1.2rem',
  color: '#3C3C3A',
  marginTop: '1rem'
};

export default function ComoLoHacemos({ data }) {
  return (
    <>
      <Grid>
        <Grid
          container
          sx={{
            backgroundColor: 'white',
            padding: 8,
            justifyContent: 'center'
          }}
        >
          <Grid item>
            <Typography sx={title}>¿Que nos diferencia?</Typography>
          </Grid>
          <Grid item>
            <Grid
              container
              maxWidth={'100%'}
              spacing={20}
              sx={{ textAlign: 'center' }}
            >
              {data.map((el, key) => (
                <Grid item xs={12} md={3} key={key} sx={{marginTop:{xs:key * 1 + 'rem', md:key * 3 + 'rem'}}}>
                  <Card
                    elevation={0}
                    sx={{
                      backgroundColor: el.color,
                      minHeight: 520,
                      width: 274,
                      borderRadius: 5
                    }}
                  >
                    <CardContent>
                      {el.icon}
                      <Typography sx={fontStyle}>{el.text}</Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <CardMedia
          component="svg"
          image={Vector20}
          sx={{
            width: '100%',
            backgroundSize: 'cover',
            backgroundPosition: 'inherit'
          }}
        ></CardMedia>
      </Grid>
    </>
  );
}
