import { Container } from "@mui/material";
import Leyenda from "../../components/plataforma/leyenda";
import PlTopStaticBanner from "../../components/plataforma/topStaticBanner";
import img23 from './../../assets/card_image5.png';

export default function plataformaLayout () {
    return (
        <>
        <Container
            disableGutters
            maxWidth={'gr'}
            sx={{ backgroundColor: 'white' }}
        >
            <PlTopStaticBanner img={img23} />
            <Leyenda />
        </Container>
        </>
    )
}