import { Container } from '@mui/material';
import TextBody from '../../components/perfilEgresados/textoBody';
import PerfilEgresados from '../../components/perfilEgresados/topStaticBanner';
import img5 from './../../assets/card_image4.jpg';

export default function PerfEgresadosLayout () {
    return (
        <Container
            disableGutters
            maxWidth={'gr'}
            sx={{ backgroundColor: '#F2F2F2' }}
        >
         <PerfilEgresados img={img5}></PerfilEgresados>
         <TextBody />
      </Container>
    )
}