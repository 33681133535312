import InstagramRoundedIcon from '@mui/icons-material/Instagram';
import YoutubeRoundedIcon from '@mui/icons-material/YouTube'; 
import logo from '../../assets/logo.png';
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  Card,
  CardMedia,
  Link
} from '@mui/material/';

export default function Footer() {
  const listItemTitle = {
    fontWeight: 700,
    fontFamily: 'Montserrat',
    fontSize: '24px' //Insert your required size
  };

  const listItemNav = {
    color: '#858583',
    fontFamily: 'Montserrat',
    lineHeight: '10px',
    fontSize: '22px', //Insert your required size
    fontWeight: 500
  };

  return (
    <Box
      flexDirection={'row'}
      justifyContent={'center'}
      color="#858583"
      width={'100%'}
      sx={{ backgroundColor: '#F2F2F2' }}
    >
      <Grid container padding={5} spacing={1}>
        <Grid item xs={12} md={6} marginY={'auto'} >
          <Box>
            <Card
              sx={{
                marginX: 'auto',
                maxwidth: '180px',
                boxShadow: 0,
                background: 'transparent'
              }}
            >
              <CardMedia
                component="img"
                sx={{ width: '150px' }}
                height={205}
                image={logo}
                alt="logo"
              />
            </Card>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>
            <List>
              <ListItem sx={{ paddingX: 0, textAlign: 'left' }}>
                <ListItemText primaryTypographyProps={listItemTitle}>
                  REDES SOCIALES
                </ListItemText>
              </ListItem>
              <Link href="https://www.instagram.com/simplifiedenglishacademy/">
                <InstagramRoundedIcon
                  sx={{ fontSize: '64px', color: '#858583' }}
                />
              </Link>
              <Link href="https://www.youtube.com/@simplifiedenglishacademy9799/about/">
                <YoutubeRoundedIcon
                  sx={{ fontSize: '64px', color: '#858583' }}
                />
              </Link>
            </List>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
