import { Container } from '@mui/material';
import QndTopStaticBanner from './../../components/quenosdiferencia/qndTopStaticBanner';
import img4 from './../../assets/card_image4.jpg';
import ComoLoHacemos from '../../components/quenosdiferencia/comoLoHacemos';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import SyncIcon from '@mui/icons-material/Sync';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import GroupIcon from '@mui/icons-material/Group';

const iconsStyle = {
  fontSize: '8rem'
};

const data = [
  {
    icon: <GroupIcon sx={iconsStyle} />,
    text: 'Ofrecemos cursos con clases individuales y reducidas.',
    color: '#F1F9FF'
  },
  {
    icon: <PendingActionsIcon sx={iconsStyle} />,
    text: 'Clases semanales con el Speaking como hilo conductor, para poner en práctica la lengua, su temática y su aplicación en contextos reales',
    color: '#E1F3FF'
  },
  {
    icon: <SyncIcon sx={iconsStyle} />,
    text: 'Clases de conversación con grupos rotativos,  para que durante tu proceso de aprendizaje y práctica, puedas conocer a varias personas, con las que compartirás el interés de intercambiar ideas, conceptos y comentarios en inglés',
    color: '#BFDCED'
  },
  {
    icon: <InsertInvitationIcon sx={iconsStyle} />,
    text: 'Acceso 24/7 a la plataforma web para poner a tu disposición los elementos claves clave en tu camino a convertirte en hablante fluido de inglés',
    color: '#E0E5EF'
  }
];

export default function QueNosDiferenciaLayout() {
  return (
    <>
      <Container
        disableGutters
        maxWidth={'gr'}
        sx={{ backgroundColor: '#F2F2F2' }}
      >
        <QndTopStaticBanner img={img4} />
        <ComoLoHacemos data={data} />
      </Container>
    </>
  );
}
