import WhatWeOffer from '../../components/services/WhatWeOffer';
import forma15 from '../../assets/Vector15.svg';
import { Container, Grid, Typography } from '@mui/material';

const layoutCss = {
  position: 'relative',
  zIndex: 999,
  backgroundColor: 'white'
};

const fontTitleStyle = {
  width: '100%',
  textAlign: 'center',
  fontFamily: 'Montserrat, sans-serif',
  color: '#3C3C3A',
  fontSize: { md: '5rem', sm: '4rem', xs: '3rem' },
  fontWeight: 700
};

const fontParagraphStyle = {
  maxWidth: '990px',
  width: '100%',
  marginTop: '61px',
  textAlign: 'center',
  fontFamily: 'Montserrat, sans-serif',
  color: '#3C3C3A',
  lineHeight: '1.5em',
  letterSpacing: '0',
  fontStyle: 'normal',
  fontSize: { md: '2.25rem', sm: '1.75rem' },
  fontWeight: 500
};

export default function WhatWeOfferLayout() {
  return (
    <Grid container sx={layoutCss}>
      <Grid
        item
        sx={{
          minWidth: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: '5rem'
        }}
      >
        <Typography sx={fontTitleStyle}>¿Qué Ofrecemos?</Typography>
        <Typography sx={fontParagraphStyle}>
          Nuestro enfoque personalizado te permitirá obtener resultados
          auspiciosos. Qué tan lejos o cuán rápidamente logres alcanzarlos,
          dependerá de ti.
        </Typography>
        <Typography sx={fontParagraphStyle}>
          No te diremos que podrás aprender inglés en X meses, pero sí que te 
          daremos todas las herramientas para que puedas hacerlo cuanto antes.
        </Typography>
      </Grid>
    </Grid>
  );
}
