import TopStaticBanner from '../../layout/services/topStaticBannerLayout.jsx';
import WhatWeOfferLayout from '../../layout/services/wwOfferLayout.jsx';

function login() {
  return (
    <div>
      <TopStaticBanner />
    </div>
  );
}

export default login;
