import SquareCard from "../components/cards/SquareCard";
import notebook from "./../assets/notebook.png";
import child from "./../assets/child.png";
import puzzle from "./../assets/puzzle.png";
import degree from "./../assets/degree.png";
import { Box } from "@mui/material";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

export default function CardCarrousesell() {

    const data = [
        {
            image: notebook,
            text: "Nuestra plataforma.",
            url: "/nuestro-hub"
        },
        {
            image: puzzle,
            text: "¿Que ofrecemos?",
            url: "/nuestros-servicios"
        },
        {
            image: child,
            text: "¿Que nos diferencia?",
            url: "/que-nos-diferencia"
        },
        {
            image: degree,
            text: "Perfil del egresado.",
            url: "/perfil-de-egresados"
        }
    ];

    const styles = {
        container: {
            backgroundColor: 'white'
        }

    };

    return (
        <>
            <Box sx={styles.container}>
                <Swiper
                    breakpoints={{
                        400: {
                            spaceBetween:5,
                            slidesPerView: 1.5
                        },
                        1200: {
                            spaceBetween:5,
                            slidesPerView: 4
                        }
                    }}
                >
                    {
                        data.map((item, key) => (
                            <SwiperSlide key={key}>
                                <SquareCard data={item} />
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
            </Box>
        </>
    )
}
