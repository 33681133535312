import { Grid, Box, Typography, Icon } from '@mui/material';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';

const typoStyle = {
  fontFamily: 'Montserrat, sans-serif',
  color: '#3C3C3A',
  lineHeight: 1.7,
  fontSize: '20px',
  fontWeight: 500,
  letterSpacing: '-1px'
};

export default function ColumnsInfo() {
  return (
    <>
      <Grid
        sx={{
          backgroundColor: '#D7D7D7',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center'
        }}
        maxWidth={'100%'}
        margin={0}
        minHeight={'max-content'}
        paddingX={8}
        paddingTop={8}
      >
        <Grid container spacing={20}>
          <Grid item md={4} xs={12}>
            <Box textAlign={'left'} marginBottom={'2rem'}>
              <AccessAlarmIcon sx={{ fontSize: '7.8rem', color: '#323232' }} />
            </Box>
            <Typography sx={typoStyle}>
                Iniciaremos con una clase de prueba de carácter gratuito de 30
                minutos de duración,
              <br />
              en la que buscaremos evaluar y conocer a las personas interesadas
              en estudiar con nosotros.
            </Typography>
          </Grid>
          <Grid item md={4} xs={12}>
            <Box textAlign={'left'} marginBottom={'2rem'}>
              <AssignmentIcon sx={{ fontSize: '7.8rem', color: '#323232' }} />
            </Box>
            <Typography sx={typoStyle}>
                Utilizamos tests de nivelación para determinar qué curso es
                adecuado para cada persona.
              <br />
              Durante la clase, te preguntaremos sobre los objetivos que quieres
              lograr, gustos, preferencias e intereses entre otros puntos clave
              que nos permitirán conocer tu perfil.
            </Typography>
          </Grid>
          <Grid item md={4} xs={12}>
            <Box textAlign={'left'} marginBottom={'2rem'}>
              <AssignmentTurnedInIcon
                sx={{ fontSize: '7.8rem', color: '#323232' }}
              />
            </Box>
            <Typography sx={typoStyle}>
                Te enviaremos un resumen de la clase, en el que te diremos
                cuáles son tus fortalezas y debilidades en el uso de la lengua y
                confirmaremos el plan de estudio para que evalúes si estudiar
                con nosotros es de tu interés.
            </Typography>
          </Grid>
        </Grid>
        <Box
          maxWidth={1176}
          sx={{
            backgroundColor: '#E0E5EF',
            paddingY: 5,
            paddingX: 8,
            borderRadius: '20px',
            marginTop: '5rem',
            position: 'relative',
            marginBottom: '-150px'
          }}
        >
          <Typography
            sx={{
              fontSize: '24px',
              color: '#323232',
              fontWeight: 700,
              textAlign: 'center',
              letterSpacing: '-1px'
            }}
          >
            Cuando el curso de comienzo, pondremos a tu disposición los recursos
            más relevantes para que puedas aprender el idioma de una manera
            dinámica y verdaderamente entretenida. Dedicamos una sección
            completa de nuestra plataforma a la redacción y explicación de los
            temas que hacen al desarrollo de las competencias lingüísticas,
            tanto en formato escrito como de audio, todos ellos con propuestas
            de prácticas orientadas a tus intereses, para incentivar el concepto
            de aprendizaje del inglés para su uso en el mundo real.
          </Typography>
        </Box>
      </Grid>
    </>
  );
}
