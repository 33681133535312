import { Grid, Box, Typography, CardMedia } from "@mui/material";
import image6 from '../../assets/card_image_6.png'


  const paragraph = {
    fontFamily: 'Montserrat',
    textAlign:'center',
    fontWeight: 500,
    fontSize: 30
  };

  
export default function Leyenda () {
    return (
        <Grid rowGap={8} container sx={{marginTop: '6rem'}}>
            <Box sx={{paddingX: 8}}>
                <Typography sx={paragraph}>
                    Si bien recomendamos utilizar una computadora
                    para una mejor visualización de contenido,
                    una Tablet o esencialmente un teléfono celular, se
                    adaptan a la perfección al objetivo de maximizar
                    el aprovechamiento del tiempo.
                </Typography>
            </Box>
            <Grid item md={6} xs={3}><CardMedia component="img" src={image6} sx={{paddingY: 0.857}}></CardMedia></Grid>
            <Grid item md={6} xs={7} sx={{paddingX: 10, display:'flex', alignItems: 'center', minHeight: '100%'}}>                        
                <Typography sx={paragraph}>
                    Queremos que utilices los
                    tiempos muertos para tu
                    beneficio. Para lograrlo, podrás
                    conectarte a la plataforma
                    desde cualquier lugar y
                    dispositivo, tanto para
                    permanecer online, como
                    para descargar textos con
                    explicaciones sobre temas
                    específicos o de tu interés,
                    que seleccionaremos para ti,
                    como para escuchar contenido
                    seleccionado de una
                    manera veloz y simple.
                </Typography>
            </Grid>
        </Grid>
    )
}