import { Container, Box } from '@mui/material';
import loginBanner from '../../assets/loginBanner.jpg';
import ResponsiveAppBar from '../../components/header/navBar/NavBar__19_12_2023.jsx';
import TopStaticBanner from '../../components/services/topStaticBanner.jsx';
import WhatWeOfferLayout from './wwOfferLayout';
import ColumnsInfo from '../../components/services/ColumnsInfo';
import TheSecret from '../../components/services/TheSecret.jsx';
import Goals from '../../components/services/goals';

export default function TopStaticBannerLayout() {
  return (
    <Container
      disableGutters
      maxWidth={'gr'}
      sx={{ backgroundColor: '#F2F2F2' }}
    >
      <TopStaticBanner img={loginBanner} />
      <WhatWeOfferLayout />
      <ColumnsInfo />
      <Goals />
      <TheSecret />
    </Container>
  );
}
