import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import logo from './../../../assets/full_color_logo.png';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CardMedia, Link, Typography } from '@mui/material';
import { Link as ScrollLink } from 'react-scroll';

const pages = [
  { name: 'Inicio', path: '/' },
  { name: 'Servicios', path: '/nuestros-servicios' },
  { name: 'Sobre nosotros', path: '/que-nos-diferencia' },
  {
    name: 'Nuestros cursos', // Este botón tendrá una lista desplegable
    submenu: [
      { name: 'Inglés para empresas', path: 'seccion0' },
      { name: 'Preparación de exámenes', path: 'seccion1' },
      { name: 'Cursos de inglés para adultos', path: 'seccion2' },
      { name: 'Inglés para principiantes', path: 'seccion3' },
    ]
  },
  { name: 'Plataforma', path: '/nuestro-hub' }
];

const ResponsiveAppBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElSubmenu, setAnchorElSubmenu] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
    handleCloseSubmenu(); // Asegurarse de cerrar el submenu cuando se cierra el menu principal
  };

  const handleOpenSubmenu = (event) => {
    setAnchorElSubmenu(event.currentTarget);
  };

  const handleCloseSubmenu = () => {
    setAnchorElSubmenu(null);
  };

  const styles = {
    button: { 
      my: 2, 
      color: 'black', 
      display: 'block', 
      background: "#00237c", 
      color: 'white',
      '&:hover' : {
        background: "#00237c", 
        color: 'white'
      } 
    }
  };

  return (
    <AppBar
      position="static"
      color="default"
      elevation={0}
      sx={{ backgroundColor: 'white', color: 'black' }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box display={'flex'} width={"100%"}>
            <Box flex={1} sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", height: '80px' }}>
              <CardMedia
                component="img"
                sx={{ maxWidth: "150px", width: "100%", objectFit: "scale-down" }}
                image={logo}
                alt="Logo"
              />
            </Box>
            <Box flex={2} sx={{ alignItems: 'center', display: 'flex', justifyContent: 'flex-end' }}>
              <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'end' }}>
                {pages.map((page) => (
                  <React.Fragment key={page.name}>
                    {page.submenu ? (
                      <div>
                        <Link
                          sx={{textDecoration: 'none'}}
                          onClick={handleOpenSubmenu}
                          aria-controls="submenu"
                          aria-haspopup="true"
                        >
                          <Button 
                          endIcon = {
                            <ExpandMoreIcon 
                              sx={{ transform: anchorElSubmenu ? 'rotate(180deg)' : 'rotate(0deg)' }}
                            />
                          }
                          sx={{ my: 2, color: 'black' }}>
                            {page.name}
                          </Button>
                        </Link>
                        <Menu
                          id="submenu"
                          anchorEl={anchorElSubmenu}
                          open={Boolean(anchorElSubmenu)}
                          onClose={handleCloseSubmenu}
                          MenuListProps={{
                            'aria-labelledby': 'submenu-button',
                          }}
                          sx={{marginTop:2, overflow: 'hidden'}}
                        >
                          {
                            page.submenu.map((subItem) => (
                              <ScrollLink
                                key={subItem.name}
                                to={subItem.path}
                                spy={true}
                                smooth={true}
                                offset={0}
                                duration={500}
                                onClick={handleCloseNavMenu}
                              >
                                <MenuItem>
                                  <Typography textAlign="center">{subItem.name}</Typography>
                                </MenuItem>
                              </ScrollLink>
                            ))
                          }
                        </Menu>
                      </div>
                    ) : (
                      <Link style={{ textDecoration: 'none' }} href={page.path}>
                        <Button sx={{ my: 2, color: 'black', display: 'block' }}>
                          {page.name}
                        </Button>
                      </Link>
                    )}
                  </React.Fragment>
                ))}
                <Link
                  href="https://platform.simplifiedenglishacademy.com.uy/" target="_blank"
                >     
                  <Button disableRipple sx={styles.button}>
                    Iniciar sesión
                  </Button>
                </Link>
              </Box>
              <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' }, justifyContent: 'flex-end' }}>
                <IconButton
                  size="large"
                  aria-label="menu"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: 'block', md: 'none' },
                  }}
                >
                  {pages.map((page) => (
                    <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                      <Link href={page.path} style={{ textDecoration: 'none' }}>
                        <Typography textAlign="center">{page.name}</Typography>
                      </Link>
                    </MenuItem>
                  ))}
                  <Link
                  href="https://platform.simplifiedenglishacademy.com.uy/" target="_blank"
                >     
                  <Button disableRipple sx={styles.button}>
                    Iniciar sesión
                  </Button>
                </Link>
                </Menu>
              </Box>
            </Box>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default ResponsiveAppBar;
