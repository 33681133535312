import { Typography, Grid, Box } from "@mui/material";

export default function TextBody () {
    const title = {
        fontFamily: 'Montserrat',
        fontWeight: 700,
        fontSize: 60,
        textAlign: 'center'
      };
      const paragraph = {
        fontFamily: 'Montserrat',
        textAlign: 'center',
        fontWeight: 500,
        fontSize: 30,
        marginTop: 5
      };
      
    return (
        <>
            <Grid>
                <Grid container sx={{ backgroundColor: 'white', padding: 8 }}>
                <Grid item>
                    <Typography sx={title}>Perfil de Egresados </Typography>
                    <Typography sx={paragraph}>
                    <Box marginY={5}>
                    Hablantes fluidos de inglés, capaces de sostener
                    conversaciones, tanto en contextos formales como
                    informales, gracias a su  pericia y al método
                    de Simplified English Academy, que fomenta
                    la práctica constante de la lengua hablada, cimentada
                    en la  adquisición de vocabulario y aprendizaje
                    dinámico de temas que permiten construir las estructuras
                    en las que se basa el inglés.
                    </Box>
                    <Box marginY={5}>
                    Personas con competencias de lectura, auditivas y escritas, 
                    capaces de comprender con facilidad textos y audios y de crear
                    contenido con estructuras variadas y acordes a lo que otros 
                    hablantes fluidos esperan de un igual.
                    </Box>
                    <Box marginY={5}>
                    El egresado podrá desenvolverse con éxito en cualquier
                    contexto donde el inglés sea la lengua predominante
                    o nativa, en  cualquier situación espontánea que así
                    lo requiera y en el ámbito académico y profesional.
                    </Box>
                    </Typography>
                </Grid>
                </Grid>
            </Grid>
        </>
    )
} 