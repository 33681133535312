import { Box, Button, CardMedia, Grid, Typography } from '@mui/material';
import girlwithbooks from '../../assets/girlWithBooks.png';
import vector17 from '../../assets/Vector17.svg';
import vector177 from '../../assets/Group177.png';

const title = {
  fontFamily: 'Montserrat, sans-serif',
  fontWeight: 600,
  fontSize: '2.5rem',
  color: '#3C3C3A',
  letterSpacing: '-1px',
  lineHeight: 1.3
};

const paragraph = {
  fontFamily: 'Montserrat, sans-serif',
  fontWeight: 500,
  fontSize: '2rem',
  color: '#3C3C3A',
  letterSpacing: '-1px',
  lineHeight: 1.3,
  marginTop: 3
};
export default function Goals() {
  return (
    <>
      <Grid
        maxWidth={'100%'}
        sx={{ backgroundColor: 'white', minHeight: '100vh', padding: 10 }}
      >
        <Grid container spacing={10} sx={{ marginTop: '3rem' }}>
          <Grid item md={5}>
            <CardMedia
              component="img"
              image={girlwithbooks}
              alt="girlwithbooks"
            />
          </Grid>
          <Grid item md={7}>
            <Typography sx={title}>
              Queremos que todos nuestros estudiantes aprueben exámenes con
              reconocimiento nacional y/o internacional.
            </Typography>
            <Typography sx={paragraph}>
              Para ello, no solamente contamos con clases semanales enteramente
              de Speaking, sino que nuestra plataforma permite a los estudiantes
              completar los ejercicios de Use of English, Reading, Writing y
              Listening e incluso Speaking, propuestos cada semana y obtener una
              estadística de resultados de cada uno.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <CardMedia
        width={'100%'}
        component="svg"
        image={vector17}
        alt="form"
        sx={{ position: 'absolute', maxWidth: '1368px', marginTop: '-1px' }}
      />
      <Box
        sx={{
          backgroundColor: '#E7E7E7',
          maxHeight: 'max-content',
          paddingX: '5rem'
        }}
      >
        <Grid
          sx={{
            minHeight: '105vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Grid container marginTop={'200px'} spacing={8}>
            <Grid item md={7}>
              <CardMedia component={'img'} image={vector177} />
            </Grid>
            <Grid
              item
              md={5}
              sx={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <Typography
                sx={{
                  fontSize: '30px',
                  fontWeight: 500,
                  fontFamily: 'Montserrat, sans-serif'
                }}
              >
                No todos los estudiantes buscan rendir exámenes. Por eso, la
                sección Topics será obligatoria sólo para para quien opte
                por dicha opción. De cualquier manera, estamos convencidos que
                los ejercicios no hacen a la esencia del inglés.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
