import react from 'react';
import styles from '../../styles/Condiciones.module.css'
import img1 from '../../assets/condiciones/1.png'
import img2 from '../../assets/condiciones/2.png'
import img3 from '../../assets/condiciones/3.png'
import img4 from '../../assets/condiciones/4.png'
import img5 from '../../assets/condiciones/5.png'
import img6 from '../../assets/condiciones/6.png'
import img7 from '../../assets/condiciones/7.png'
import img8 from '../../assets/condiciones/8.png'

function Condicion(props) {

    const {logo, texto} = props;
   
    return (
<div className={styles.condicion}>
    <div className={styles.circulo}>
        <img src={logo} className={styles.logo}/>
    </div>
    
    <div className={styles.texto}>
        {texto}
    </div>
       
</div>
    )
}

function CosoMedio(){
    return(
        <div className={styles.cosoMedio}>
            <div className={styles.circuloCosoMedio}></div>
            <div className={styles.lineaCosoMedio}></div>
        </div>
    )
}

function CartelArriba(){
return(
    <div className={styles.cartel}>
        <div className={styles.cartelIzquierda}></div>
        <div className={styles.cartelCentro}>
        Condiciones de los cursos
        </div>
        <div className={styles.cartelDerecha}></div>
    </div>
   ) 
}


 export default function Condiciones() {

    let condiciones = [
        {logo:img1,texto:"En cursos grupales, utilizamos tres criterios de separación."},
        {logo:img2,texto:"Los criterios son objetivos edad y nivel"},
        {logo:img3,texto:"En la modalidad para adolescentes, la sección Topics es de carácter obligatorio"},
        {logo:img4,texto:"En la modalidad para adultos, es opcional"},
        {logo:img5,texto:"No hay obligatoriedad de asistir a las clases de conversación"},
        {logo:img6,texto:"El contenido de cada semana se agrega cada lunes a las 00:00 horas"},
        {logo:img7,texto:"El contenido de la sección Topics está disponible durante la totalidad del curso y hasta una semana después de su finalización"},
        {logo:img8,texto:"El contenido de la sección 'English for the Real World' también estará disponible durante la totalidad del curso y hasta una semana después de su finalización."}
    ]

    let divisor = true;

    return (
        <div className={styles.contenedor}>

            <CartelArriba />
            <div className={styles.contenedorCondiciones}>
                {condiciones.map((cond, key) => (

                    <div className={styles.divisor} key={key}> {/* Faltaba agregar la key. Esto sirve para que el DOM puede agregarle un indice a cada elemento que
                    se va a renderizar y tratarlos como distintos.*/}
                        <Condicion logo = {cond.logo} texto = {cond.texto} />
                            {divisor == true && <CosoMedio />}
                        {divisor = !divisor}
                    </div>

                ))}    
            </div> 
        </div>
    )
}