import { Box, CardMedia, Typography } from "@mui/material"
import { Link } from "react-router-dom"

export default function SquareCard ({data}) {
    const styles = {
        container: {
            padding: 1,
            marginTop: 3
        },
        image: {
            width: "100%",
            maxWidth: "312px",
            minWidth: "200px"

        },
        title: {
            fontWeight: 600,
            textAlign: "center",
            marginTop: 3,
            fontFamily: 'Roboto, sans-serif',
            fontSize: 24,
            textStyle: "none"
        },
        link: {
            textDecoration: "none",
            fontWeight: 600,
            textAlign: "center",
            fontFamily: 'Roboto, sans-serif',
            fontSize: 24,
            textStyle: "none"
        }
    }

    return (
        <>
            <Link
                to={data.url}
                style={styles.link}

            >
                <Box sx={styles.container}>
                    <CardMedia 
                        src={`${data.image}`}
                        component="img"
                        sx={styles.image}
                    />
                    <Typography sx={styles.title}>
                        {data.text}
                    </Typography>
                </Box>
            </Link>
        </>
    )
}