import { Grid, Box, Typography } from '@mui/material';

const title = {
  fontFamily: 'Montserrat',
  fontWeight: 700,
  fontSize: 60,
  textAlign: 'center'
};
const paragraph = {
  fontFamily: 'Montserrat',
  fontWeight: 500,
  fontSize: 30,
  marginTop: 5
};

export default function TheSecret() {
  return (
    <>
      <Grid>
        <Grid container sx={{ backgroundColor: '#E7E7E7', padding: 8 }}>
          <Grid item>
            <Typography sx={title}>¡Ese es el secreto! </Typography>
            <Typography sx={paragraph}>
              <Box marginY={5}>
                El inglés no debe ser abstracto, sino de uso diario y debe ser
                capaz de crear un interés genuino en quien lo está aprendiendo.
              </Box>
              <Box marginY={5}>
                ¿Es eso posible? ¡Claro! El método de Simplified ha sido creado
                con ese objetivo.
              </Box>
              <Box marginY={5}>
                Por razones evidentes, un estudiante de un idioma extranjero no
                se desenvuelve de la misma manera cuando practica ese idioma que
                cuando habla su lengua materna.
              </Box>
              <Box marginY={5}>
                En Simplified queremos que cuando hables en inglés, seas la
                misma persona que en español. Sin embargo, si aún no te has
                convertido en quien quieres ser, te daremos las herramientas y
                el apoyo para que puedas alcanzar tu objetivo de hablar inglés
                fluidamente y ser quienes te ayudaron a lograr una meta que
                alguna vez pareció ser un imposible.
              </Box>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
