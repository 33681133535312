
import { Container, Slide, Typography } from '@mui/material';
import BotonWhatsapp from '../../components/botonWhatsapp';
import Banner from '../../components/body/home/Banner.jsx';
import CardCarrousesell from '../../layout/CardCarroussell.jsx';
import BodyCard from '../../components/cards/BodyCard.jsx';
import empresas from '../../assets/empresas.png'
import examenes from '../../assets/examenes.png'
import adultos from '../../assets/adultos.png'
import principiantes from '../../assets/principiantes.png'
import "swiper/css";
import "swiper/css/pagination";

const data = [
  {
    background:"white",
    titleColor: "#950000",
    textColor: "black",
    buttonBackgroundColor: "#950000",
    buttonTextColor: "white",
    image: empresas,
    message:"Hola,+quisiera+mas+información+sobre+los+cursos+para+empresas.",
    title: "Inglés para empresas",
    subtitle: "Maximice el potencial de sus empleados",
    leyenda: [
      {
        title: "Aprendizaje basado en conversación.",
        text: "Interacción Activa: Clases enfocadas 100% en conversación para mejorar habilidades comunicativas y agilidad mental."
      },
      {
        title: "Inmersión en la Práctica:",
        text: "Aprender inglés se convierte en una actividad social y profesional, impulsando la motivación y el éxito. Grupos Pequeños: Clases en grupos reducidos (Máximo 4 personas), garantizando atención personalizada y participación constante."
      },
      {
        title: "Personalización según Rol:",
        text: "Material y temáticas seleccionadas según las necesidades de sus empleados."
      },
      {
        title: "Acceso a Plataforma Interactiva.",
        text: "Disponibilidad 24/7 adaptado al ritmo de trabajo de tu empresa. Creación de Hábito: Integración del inglés en la rutina diaria para progresar de manera eficiente y natural."
      }
    ]
  },
  {
    background:"#003FA0",
    titleColor: "white",
    textColor: "white",
    buttonBackgroundColor: "white",
    buttonTextColor: "#003FA0",
    image: examenes,
    message:"Hola+quisiera+conocer+mas+sobre+los+cursos+para+la+preparación+de+examenes.",
    title: "Preparación de exámenes",
    subtitle: "Mas allá de los tests",
    leyenda: [
      {
        title: "Aprendizaje basado en conversación.",
        text: "Aunque el Speaking es crucial, entendemos que representa solo una parte del examen internacional. Nuestro método abarca todas las habilidades necesarias, no solo la conversación."
      },
      {
        title: "Más que memorización.",
        text: "Enfocamos el aprendizaje en la comprensión y el razonamiento, no en la repetición. Aprendiendo inglés de manera comunicativa, nuestros estudiantes dominan tanto la gramática como el vocabulario."
      },
      {
        title: "Preparación efectiva.",
        text: "Nuestro enfoque está basado en un análisis detallado de las estructuras y criterios de evaluación de los exámenes internacionales, asegurando una preparación completa y eficiente."
      },
      {
        title: "Comprobalo.",
        text: "Te invitamos a comprobar la efectividad de nuestro método con los resultados tangibles de nuestros estudiantes."
      }
    ],
    callToActionText: "¿Listo para triunfar en tu examen internacional?"
  },
  {
    background:"white",
    titleColor: "#AE7D00",
    textColor: "black",
    buttonBackgroundColor: "#AE7D00",
    buttonTextColor: "white",
    image: adultos,
    message: "Hola,+quisiera+mas+información+sobre+los+cursos+para+adultos.",
    title: "Cursos de inglés para adultos",
    subtitle: "Invierte en tu futuro",
    leyenda: [
      {
        title: "Aprendizaje centrado en el habla.",
        text: "En nuestras clases, hablarás desde el principio hasta el fin. Esto mejora tus habilidades comunicativas y vincula la teoría con la práctica, potenciando tu agilidad mental."
      },
      {
        title: "Clases personalizadas.",
        text: "Ofrecemos clases individuales o en grupos reducidos de hasta 3 estudiantes. Con un enfoque 100% en conversación, podrás hablar sobre tus intereses y objetivos sin interrupciones."
      },
      {
        title: "Integración en tu rutina diaria.",
        text: "Te ayudamos a crear un hábito sólido para incorporar el inglés en tu día a día.\nPlataforma Web Interactiva: Organiza tu estudio del inglés de manera eficiente y dinámica, disponible en cualquier momento."
      },
      {
        title: "Práctica completa.",
        text: "Enfoque en diversos aspectos del idioma, desde lo académico hasta lo conversacional e informal."
      }
    ],
    callToActionText: "¿Estás Listo para Avanzar en Tu Aprendizaje del Inglés?"
  },
  {
    background:"#449368",
    titleColor: "white",
    textColor: "white",
    buttonBackgroundColor: "white",
    buttonTextColor: "#449368",
    image: principiantes,
    message:"Hola+quisiera+mas+información+sobre+los+cursos+para+principiantes.",
    title: "Inglés para principiantes",
    subtitle: "¡Felicitaciones por tomar la decisión de estudiar inglés!",
    leyenda: [
      {
        title: "",
        text: "Si bien dar el ¡sí quiero! es un paso necesario para alcanzar el éxito, es incuestionable que no lo garantiza. Como cada día, tus acciones y decisiones del hoy, sentarán las bases del mañana, así que cabe preguntarse ¿qué será del inglés en tu futuro?\n¿Lo pondrás en el rincón de la memoria donde se alojan tus logros personales? ¿Quedará en el recuerdo junto al día que terminaste la escuela, el liceo, aquella mañana en la que te graduaste en la facultad o aquel momento en el que finalmente conseguiste algo importante por lo que trabajaste cientos o miles de horas?"
      },
      {
        title: "",
        text: "Queremos que el inglés se convierta en uno de tus motivos de orgullo.Por ello, hemos desarrollado una metodología de estudio que trabaja el aprendizaje de la lengua desde múltiples aspectos y que te dará las herramientas necesarias para alcanzar el éxito. El futuro es una hoja en blanco y nosotros queremos ayudarte a escribirla"
      }
    ],
    callToActionText: "Comienza a hablar inglés hoy !"
  }
]

function home() {
  return (
    <Container maxWidth={'xl'} disableGutters>
      <Banner />
      <BotonWhatsapp />
      {/* <Swiper
        slidesPerView={1.33}
        spaceBetween={90}
        pagination={{
          clickable: true,
        }}
        className="mySwiper"
      > */}
        {/* <SwiperSlide> */}
          <CardCarrousesell />
        {/* </SwiperSlide> */}
      {/* </Swiper> */}
      {
        data.map((item, key)=> (
          <BodyCard data={item} key={key} id={'seccion'+key} />
        ))
      }
    </Container>
  );
}

export default home;
