import Login from './../../components/body/login/Login';

function login() {
  return (
    <div>
      <Login />
    </div>
  );
}

export default login;
