import QueNosDiferenciaLayout from '../../layout/quenosdiferencia/QueNosDiferenciaLayout';
import Condiciones from '../../components/home/Condiciones';
export default function quenosdiferencia() {
  return (
    <>
      <div>
        <QueNosDiferenciaLayout />
        <Condiciones />
      </div>
    </>
  );
}
