import { Grid, CardMedia } from "@mui/material"
import vector18 from '../../assets/Vector18.svg';

const mainBox = {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 999,
    maxwidth: { xs: '100%', md: '100%' },
    minHeight: '100%'
  };
  
  const forma = {
    position: 'absolute',
    zIndex: 999,
    backgroundSize: '100%',
    backgroundPosition: 'bottom',
    maxWidth: '1368px',
    bottom: '-1px',
    width: '100%',
    height: '100%'
  };


export default function PerfilEgresados ({ img }) {
    return(
        <>
            <Grid>
                <Grid container sx={mainBox}>
                    <CardMedia
                    component="img"
                    sx={{
                        minHeight: 500,
                        backgroundSize: { md: '100%', xs: '90%' },
                        objectPosition: { xs: 'left', md: 'center' }
                    }}
                    image={img}
                    alt="green iguana"
                    ></CardMedia>
                    <CardMedia
                    component="svg"
                    sx={forma}
                    image={vector18}
                    alt="green iguana"
                    ></CardMedia>
                </Grid>
            </Grid>
        </>
    )
}