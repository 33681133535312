import { Box, Button, CardMedia, Link, TextField, Typography } from "@mui/material";
import image from './../../../assets/svg_item.svg'

export default function Banner (data) {
    const styles = {
        itemSvg: {
            width: {
                xs: '-webkit-fill-available',
                md: 'fit-content'
            },
            height: '558px',
        },
        container: {
            width: "100%",
            height: "558px",
            backgroundImage: 'url('+require('./../../../assets/main_banner.jpg')+')',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            position: 'relative'
        },
        rightWrapper: {
            postition: 'relative',    
            display: 'flex',
            alignItems: 'center'
        },
        rightWrapperItem: {
            maxWidth: '390px',
            position: 'absolute',
            left:{
                xs: '10px',
                md: '100px',
            }
        },
        title: {
            fontSize: 42,
            fontWeight: 900,
            lineHeight: 1,
            color: 'white',
            fontFamily: 'Montserrat , sans-serif'
        },
        paragraph: {
            marginTop: "23px",
            fontSize: 20,
            fontWeight: 400,
            lineHeight: 1.5,
            color: 'white',
            fontFamily: 'Montserrat , sans-serif'
        },
        ctaBanner: {
            padding: 2,
            marginTop: "23px",
            fontWeight: "600",
            fontSize: "16px",
            background: "#D0142C",
            color: "white",
            // textTransform: "uppercase",
            "&:hover": {
                background: "#D0142C",
            }
        }
        
    }
    return (
        <>
            <Box sx={styles.container}>
                <Box sx={styles.rightWrapper}>
                    <CardMedia
                        src={image}
                        component="img"
                        sx={styles.itemSvg}
                    />
                    <Box sx={styles.rightWrapperItem}>
                        <Typography sx={styles.title}>
                            Inglés para el mundo real
                        </Typography>
                        <Typography sx={styles.paragraph}>
                            Transformamos la complejidad en simplicidad.<br></br><br></br> Con nuestras herramientas de aprendizaje enfocadas y efectivas, te motivamos a integrar el inglés en tu vida cotidiana de forma natural y sencilla.<br></br><br></br> Descubre el inglés práctico y utilízalo desde el primer día.
                        </Typography>
                        <Link href={`https://wa.me/59894938312/?text=Hola,+Quisiera+agendar+una+clase+de+prueba`} target="__blank">
                            <Button sx={styles.ctaBanner} >comienza tu lección gratuita ahora</Button>
                        </Link>
                    </Box>
                </Box>
            </Box>
        </>
    )
} 