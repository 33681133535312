import { Grid, Box, CardMedia, Typography } from "@mui/material"
import vector23 from '../../assets/Vector23.svg';

const mainBox = {
    position: 'relative',
    display: 'flex',
    background: 'linear-gradient(to bottom, lightgray 5%, white 95%)',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 999,
    height:600,
    maxwidth: { xs: '100%', md: '100%' },
    minHeight: '100%'
  };
  
  const forma = {
    position: 'absolute',
    zIndex: 999,
    backgroundSize: '100%',
    backgroundPosition: 'bottom',
    maxWidth: '1368px',
    bottom: '-1px',
    width: '100%',
    height: '100%'
  };

  const title = {
    fontFamily: 'Montserrat',
    fontWeight: 700,
    fontSize: 50,
    textAlign: {xs:'center', md:'left'}
  };
  const paragraph = {
    fontFamily: 'Montserrat',
    fontWeight: 500,
    fontSize: 30,
    marginTop: 1,
    textAlign: {xs:'center', md:''}
  };

export default function plTopStaticBanner ({img}) {
    return (
        <>
            <Grid>
                <Grid container sx={mainBox}>
                    <Grid item md={6} sx={{display: 'flex', justifyContent: 'end'}}>
                        <CardMedia
                        component="img"
                        sx={{
                            maxWidth: 500,
                            minHeight: 500,
                            objectFit: 'contain',
                            objectPosition: { xs: 'left', md: 'center' }
                        }}
                        image={img}
                        ></CardMedia>
                    </Grid>
                    <Grid item md={6} sx={{display: 'flex', justifyContent: 'center', alignItems: 'flex-start', minHeight: 300}}>
                        <Box sx={{maxWidth: "500px", position: 'relative', zIndex: 9999, }}>
                            <Typography sx={title}>Multiplataforma</Typography>
                            <Typography sx={paragraph}>aprende donde quiera que estés.</Typography>
                        </Box>
                    </Grid>
                    <CardMedia
                    component="svg"
                    sx={forma}
                    image={vector23}
                    ></CardMedia>
                    
                </Grid>
            </Grid>
        </>
    )
}