import axios from 'axios';
import { useState } from 'react';
import {Box, TextField, Container, Button, Card, CardMedia, Grid, Alert, Typography} from '@mui/material/'
import { useNavigate } from "react-router-dom";

 export default function Login () {

    const history                   = useNavigate();
    const [title, setTitle]         = useState('¡W E L C O M E!')
    const [pass, setPass]           = useState('')
    const [user, setUser]           = useState('')
    const [error, setError]         = useState(false)
    const [errormsg, setErrormsg]   = useState("Usuario o contraseña invalidos")

    let loginIn = function () {
        history('/')
    }

    let Errors = function () {
        setError(true);       
    }

    let sendData = async function () {

        const data = {
            "email": "federlrpm@gmail.com",
            "password": "1234"
        }


        //const validation = user === "Pedro" && pass==="1234" ? loginIn() : Errors();

           
        axios.post("https://www.simplifiedenglishacademy.com.uy/users/login", data)
        .then((res) => res.data.response ? loginIn() : console.log("INTENTE NUEVAMENTE"))
        .catch((error) => console.log(error))
    
    }

    return (
        <Box 
            display="flex" 
            textAlign="center"
            alignItems="center" 
            height="500px" 
            // bgcolor="#1976d2"
            borderRadius="10px"
            boxShadow="3"
            padding="0px" 
            overflow="hidden"
            >
            <Grid item borderRadius="0" sx={
                    { 
                        display: { 
                            xs: 'none', 
                            sm:'none', 
                            md: 'flex'
                        } 
                    }
                }
            >
            <Card sx={{ maxwidth: '700px', minHeight: '100%' }}>
                <CardMedia 
                    component="img"
                    width="100%"
                    image="./../static/book.jpg" >
                </CardMedia>
            </Card>
            </Grid>
            <Grid padding="25px">
                <Container>
                    <Box textAlign="center" fontSize="1.25em">
                        <Typography className="margin-none">{title}</Typography>
                    </Box>
                    <Box textAlign="center" fontSize="1.5em">
                    <Typography component={'span'} variant={'body2'}>
                        To the next custom english learning experience. 
                    </Typography>
                        <a style={{color: "orange"}} href="www.google.com"><strong> Try Pro</strong></a>
                    </Box>
                    <Box textAlign="left">
                        <label htmlFor="user">Ingresa tu usuario</label>
                        <TextField onChange={(e) => setUser(e.target.value)} name="user" margin="dense" type="text" label="usuario@mail.com" color="primary" fullWidth />
                        <label htmlFor="pwd">Ingresa tu contraseña</label>
                        <TextField onChange={(e) => setPass(e.target.value)} name="pwd" margin="dense" type="password" label="Contraseña" color="primary" fullWidth />
                    </Box>
                    {error === true ? <Alert severity="error">{errormsg}</Alert> : ""}
                    <Box mt="20px">
                        <Button onClick={sendData} style={{minHeight: '4rem',}} variant="contained" size="large" fullWidth>TRY TO LOGIN</Button>
                    </Box>
                </Container>
            </Grid>
        </Box>
    )
}