import React from 'react';
import ReactDOM from 'react-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Routes, Route } from 'react-router-dom';
import './index.css';
import { Router, BrowserRouter } from 'react-router-dom';
import NavBar from './components/header/navBar/NavBar';
import Home from './pages/home/index';
import Services from './pages/services/index';
import QueNosDiferencia from './pages/quenosdiferencia/index';
import PerfEgresados from './pages/perfilEgresados/index';
import Plataforma from './pages/plataforma';

import Login from './pages/login/index';
import Footer from './components/footer/footer';
import { Container } from '@mui/material';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1268,
      gr: 1368,
      lg: 1536,
      xl: 1920
    }
  }
});

let createdTheme = createTheme(theme);

ReactDOM.render(
  <Container maxWidth={'xl'} style={{backgroundColor: 'white'}}>
    <ThemeProvider theme={createdTheme}>
      <React.StrictMode>
        <BrowserRouter basename='/'>
          <NavBar />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/que-nos-diferencia" element={<QueNosDiferencia />} />
            <Route path="/nuestros-servicios" element={<Services />} />
            <Route path="/perfil-de-egresados" element={<PerfEgresados />} />
            <Route path="/nuestro-hub" element={<Plataforma />} />
            <Route path="/login" element={<Login />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      </React.StrictMode>
    </ThemeProvider>
  </Container>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
