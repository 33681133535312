import logo from "../assets/WhatsApp_Logo_2.png";
export default function BotonWhatsapp() {
  return (
    <>
      <a style={{ cursor: "pointer" }} href="https://wa.me/59894938312/" target={'_blank'} rel="noreferrer">
        <img
          src={logo}
          style={{
            maxWidth: "80px",
            position: "fixed",
            bottom: "3.5rem",
            zIndex: "99999",
            right: "3.5rem",
            filter: 'drop-shadow(9px 6px 13px lightgreen)'
          }}
          alt="Comunicate con nosotros"
        />
      </a>
    </>
  );
}